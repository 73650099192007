<template>
  <div :class="{ 'top-panel': true, 'is-filter': filter }">
    <div v-show="!filter">
      <Search placeholder="Rechercher dans cette page" :loading="true" />

      <q-btn
        label="Filtrer"
        :disable="!getEntitiesProject.length"
        class="btn-filter"
        @click="filter = !filter"
      >
        <q-icon class="filter-icon" />
      </q-btn>

      <q-btn
        label="Vérifier les infos"
        class="btn-filter"
        @click="
          $router.push(`/review-information/${$route.params.projectId}`)
        "
      >
        <q-icon class="review-icon" />
      </q-btn>

      <!-- If at one point we decide to give the possibility for the user to review only low confidence data -->
      <!-- <q-btn-dropdown
        :disable="!getEntitiesProject.length"
        label="Vérifier les infos"
      >
        <q-list>
          <q-item
            clickable
            v-close-popup
            @click="
              $router.push(`/review-information/${$route.params.projectId}`)
            "
          >
            <q-item-section>
              <q-item-label>Toutes les informations</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            v-close-popup
            @click="
              $router.push(
                `/review-information/${$route.params.projectId}/?filterDataConfidences=LOW`
              )
            "
          >
            <q-item-section>
              <q-item-label>Seulement confiance faible</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown> -->

      <q-btn
        label="Analyse en cours"
        :class="{
          'btn-progress-documents': true,
          'is-loading': loadingProcess
        }"
        v-if="isShowProgress"
        :disable="true"
      >
        <q-spinner v-show="loadingProcess" class="on-right" />
      </q-btn>

      <q-space />

      <q-btn
        class="btn-new"
        icon-right="add"
        label="Ajouter une entité"
        @click="isAdd = true"
      />
    </div>

    <AddModal :is-show.sync="isAdd" @closeModal="closeAddModal" />

    <FilterContainer
      v-show="filter"
      :filter="filter"
      @closeFilter="filter = !filter"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const FilterContainer = () => import('@/components/project/FilterConteiner')
const AddModal = () => import('@/components/modals/AddEntity')
const Search = () => import('@/components/global/Search')

export default {
  name: 'TopPanel',
  props: {
    refreshProcessingButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddModal,
    FilterContainer,
    Search
  },
  data() {
    return {
      filter: false,
      isAdd: false,
      isShowProgress: false,
      loadingProcess: false,
      timer: 30000,
      count: 0
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getEntitiesProject',
      'getFilter',
      'getErrorEntitiesProject',
      'getErrorEntitiesData'
    ])
  },
  created() {
    this.fetchProcess()
    this.$options.interval = setInterval(this.fetchProcess, this.timer)
  },
  methods: {
    ...mapActions('documents', ['fetchProgressDocuments']),
    ...mapActions('projects', ['fetchEntitiesData', 'fetchEntitiesProject']),
    async refreshData() {
      if (this.count && this.getEntitiesProject) {
        const { projectId } = this.$route.params
        await this.fetchEntitiesProject({ projectId })

        this.getEntitiesProject.forEach(entity => {
          this.fetchEntitiesData({
            entityId: entity.entityId,
            filter: this.getFilter
          })
        })
      }
    },
    closeAddModal() {
      this.isAdd = false
    },
    async fetchProcess() {
      this.loadingProcess = true
      const { projectId } = this.$route.params
      const result = await this.fetchProgressDocuments({ projectId })

      if (typeof result === 'boolean' && !result) {
        this.isShowProgress = false
        this.loadingProcess = false
        clearInterval(this.$options.interval)
        this.refreshData()
        return
      }

      if (typeof result === 'string') {
        this.showNotify(result)
        return
      }
      this.count += 1
      this.isShowProgress = true
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    refreshProcessingButton: function(status) {
      if (status) {
        this.count = 0
        this.fetchProcess()
        this.$emit('refreshDone')
      }
    },
    getErrorEntitiesProject: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorEntitiesData: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  },
  beforeDestroy() {
    clearInterval(this.$options.interval)
  }
}
</script>
